<script lang="ts" setup>
import WeddingPhotoOne from '../assets/wedding-photo-1.jpg'
import WeddingPhotoTwo from '../assets/wedding-photo-2.jpg'
import WeddingPhotoThree from '../assets/wedding-photo-3.jpg'
import WeddingPhotoFour from '../assets/wedding-photo-4.jpg'
import WeddingPhotoSix from '../assets/wedding-photo-6.jpg'

import { ref } from 'vue'

const products = [
    {
        image: WeddingPhotoOne,
    },
    {
        image: WeddingPhotoTwo,
    },
    {
        image: WeddingPhotoThree,
    },
    {
        image: WeddingPhotoFour,
    },
    {
        image: WeddingPhotoSix,
    },
]
const responsiveOptions = ref([
    {
        breakpoint: '1400px',
        numVisible: 2,
        numScroll: 1,
    },
    {
        breakpoint: '1199px',
        numVisible: 3,
        numScroll: 1,
    },
    {
        breakpoint: '767px',
        numVisible: 2,
        numScroll: 1,
    },
    {
        breakpoint: '575px',
        numVisible: 1,
        numScroll: 1,
    },
])
</script>

<template>
    <div
        class="relative p-6 overflow-hidden flex justify-center flex-column align-items-center"
    >
        <div class="text-center my-3 relative max-w-xl">
            <h1 class="text-7xl rochester-regular">
                Photo Collection for Your Wedding & Events Made Simple!
            </h1>
            <div class="text-xl">
                <p>
                    SnapPix allows you to share a QR code with your event guest
                    and then all they need to do is take a snap and done!
                </p>
                <small>
                    The photo is automatically uploaded to your event, no more
                    awkward uploading of photos from your guests.
                </small>
            </div>
            <div>
                <i class="pi pi-heart text-7xl absolute top-0 -left-20"></i>
            </div>
        </div>
        <Carousel
            :value="products"
            :numVisible="3"
            :numScroll="1"
            :responsiveOptions="responsiveOptions"
            circular
            :autoplayInterval="3000"
        >
            <template #item="slotProps">
                <div class="surface-border border-round m-2 p-3">
                    <img
                        :src="slotProps.data.image"
                        alt="image"
                        class="w-full border-round"
                    />
                </div>
            </template>
        </Carousel>
    </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Rochester&display=swap');

.rochester-regular {
    font-family: 'Rochester', cursive;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 3px;
}
</style>
