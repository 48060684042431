import { createApp } from 'vue'
import App from './components/App.vue'
import PrimeVue from 'primevue/config'
import StyleClass from 'primevue/styleclass'
import Ripple from 'primevue/ripple'
import router from './router'
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice'

import './index.css'
import './style.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import 'primevue/resources/themes/aura-light-amber/theme.css'

createApp(App)
    .use(PrimeVue, { ripple: true })
    .use(router)
    .use(ToastService)
    .use(ConfirmationService)
    .directive('styleclass', StyleClass)
    .directive('ripple', Ripple)
    .mount('#app')
