import { createRouter, createWebHistory } from 'vue-router'
import Home from './components/Home.vue'
import Base from './components/Base.vue'
import PageNotFound from './components/PageNotFound.vue'

const routes = [
    {
        path: '/',
        name: 'Base',
        component: Base,
        children: [
            {
                path: '',
                name: 'Home',
                component: Home,
            },
            { path: '/:pathMatch(.*)*', component: PageNotFound },
        ],
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
})

export default router
